import * as React from "react";

import { default as MuiSwitch, SwitchProps } from "@mui/material/Switch";

import { adaptProps } from "../mui-stories-utils";
import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";

const Switch = (
  componentProps: SwitchProps
): React.ReactElement<SwitchProps> => {
  const { children, props } = adaptProps(componentProps);

  return (
    <ThemeLayout>
      <MuiSwitch
        sx={{
          width: "62px",
          height: "42px",
          ".MuiButtonBase-root": {
            top: "5px",
            left: "5px"
          },
          ".MuiSwitch-thumb": {
            color: colors.white[0],
            width: "13px",
            height: "13px"
          },
          ".MuiSwitch-track": {
            height: "17px",
            borderRadius: "10px",
            backgroundColor: colors.gray[60],
            opacity: 1
          },
          ".MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: colors.blue[70],
            opacity: 1
          }
        }}
        {...props}
      >
        {children}
      </MuiSwitch>
    </ThemeLayout>
  );
};

export default Switch;
