import React from "react";

import { Trans } from "@lingui/react";
import Paper from "@mui/material/Paper";
import {
  CalendarBlank,
  CloudX,
  CalendarStar,
  FirstAidKit,
  TreePalm,
  User
} from "@phosphor-icons/react";
import clsx from "clsx";

import { getDateStrObj } from "../misc";
import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";
import Typography from "../Typography/Typography";

const DEFAULT_LANGUAGE = "en-US";

type BalanceCategories = "sick" | "pto" | "vacation" | "flex" | "other";

export type Balance = {
  kind: BalanceCategories;
  label?: Record<string, string>;
  unit?: string;
  value?: any;
};

type BalanceCardProps = {
  balances?: Balance[];
  lastUpdatedDate?: string;
  variant?: "employee" | "admin";
  showHeader?: boolean;
  language?: string;
};

type BalanceCategory = {
  label: string;
  icon: React.ReactElement;
  color: string;
  value?: any;
  unit: string;
};

const BalanceCard = ({
  balances,
  lastUpdatedDate,
  variant = "employee",
  language = DEFAULT_LANGUAGE,
  showHeader = true
}: BalanceCardProps): React.ReactElement => {
  const balanceCategories: Record<
    BalanceCategories,
    Omit<BalanceCategory, "count">
  > = {
    sick: {
      label: "Sick",
      icon: <FirstAidKit size={32} color={colors.grape[20]} />,
      color: colors.grape[80],
      unit: "Hours"
    },
    pto: {
      label: "PTO",
      icon: <User size={32} color={colors.coral[30]} />,
      color: colors.coral[80],
      unit: "Hours"
    },
    vacation: {
      label: "Vacation",
      icon: <TreePalm size={32} color={colors.blue[20]} />,
      color: colors.blue[80],
      unit: "Hours"
    },
    flex: {
      label: "Flex",
      icon: <CalendarStar size={32} color={colors.coconut[20]} />,
      color: colors.coconut[80],
      unit: "Hours"
    },
    other: {
      label: "Other",
      icon: <CalendarBlank size={32} color={colors.gray[10]} />,
      color: colors.gray[80],
      unit: "Hours"
    }
  };

  const CardContainer = ({
    children
  }: {
    children: React.ReactNode;
  }): React.ReactElement => (
    <div className="flex min-h-[48px] max-w-[343px] flex-shrink flex-grow basis-full flex-row items-center justify-start gap-4">
      {children}
    </div>
  );

  const IconContainer = ({
    children,
    isUnavailable = false,
    backgroundColor
  }: {
    children: React.ReactNode;
    isUnavailable?: boolean;
    backgroundColor?: string;
  }): React.ReactElement => (
    <div
      className={clsx(
        "flex h-12 w-12 items-center justify-center rounded-lg",
        !isUnavailable && "p-2"
      )}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );

  const TextContainer = ({
    children
  }: {
    children: React.ReactNode;
  }): React.ReactElement => (
    <div className="flex h-14 flex-col justify-center">{children}</div>
  );

  const Header = ({
    variant = "employee"
  }: {
    variant?: BalanceCardProps["variant"];
  }): React.ReactElement => {
    const LastUpdatedDate = ({
      lastUpdatedDate
    }: {
      lastUpdatedDate?: string;
    }): JSX.Element => {
      if (!lastUpdatedDate) {
        return <></>;
      }
      const { day, time } = getDateStrObj(lastUpdatedDate);
      return (
        <Typography variant="caption" color={colors.gray[30]}>
          <Trans id="attendancePointsBar.header.date" values={{ day, time }}>
            as of {day} at {time}
          </Trans>
        </Typography>
      );
    };

    const typographyVariant = {
      admin: "h2",
      employee: "h1"
    }[variant] as "h1" | "h2";
    return (
      <div className={clsx(variant === "employee" && "w-full")}>
        <Typography variant={typographyVariant} color={colors.teal[20]}>
          <Trans id="surveyBalances.title.availableBalances">
            Available Balances
          </Trans>
        </Typography>
        <LastUpdatedDate lastUpdatedDate={lastUpdatedDate} />
      </div>
    );
  };

  const SingleBalanceCard = (balance: BalanceCategory): React.ReactElement => (
    <CardContainer>
      <IconContainer backgroundColor={balance.color}>
        {balance.icon}
      </IconContainer>
      <TextContainer>
        <Typography
          variant="h1"
          className="max-w-[230px] overflow-hidden text-ellipsis whitespace-nowrap"
          color={colors.gray[10]}
        >
          {`${balance.value}`}
        </Typography>
        <Typography color={colors.gray[20]}>
          {balance.label} ({balance.unit})
        </Typography>
      </TextContainer>
    </CardContainer>
  );

  const UnavailableBalanceCard = ({
    label,
    unit
  }: Pick<BalanceCategory, "label" | "unit">): React.ReactElement => (
    <CardContainer>
      <IconContainer isUnavailable={true}>
        <CloudX size={48} color={colors.gray[40]} />
      </IconContainer>
      <TextContainer>
        <Typography variant="h3" color={colors.gray[30]}>
          Balance Unavailable
        </Typography>
        <Typography color={colors.gray[20]}>
          {label} ({unit})
        </Typography>
      </TextContainer>
    </CardContainer>
  );

  const sortAlphabetically = (b1: Balance, b2: Balance): number =>
    (b1.label?.[language] ?? b1.kind).localeCompare(
      b2.label?.[language] ?? b2.kind
    );

  const balanceCards = (balances || [])
    .sort(sortAlphabetically)
    .map(({ kind, value, unit, label }) => {
      const balanceCategory = balanceCategories[kind] ?? balanceCategories;
      balanceCategory.label = label?.[language] ?? balanceCategory.label;
      balanceCategory.unit = unit ?? balanceCategory.unit;
      if (value === undefined) {
        return <UnavailableBalanceCard key={kind} {...balanceCategory} />;
      }
      return (
        <SingleBalanceCard key={kind} value={value} {...balanceCategory} />
      );
    });

  const AdminVariant = ({
    balanceCards
  }: {
    balanceCards: JSX.Element[];
  }): React.ReactElement => (
    <Paper className="!p-5 w-full h-full">
      <div className="flex flex-col gap-6">
        {showHeader && <Header variant="admin" />}
        <div className="flex flex-row flex-wrap gap-6">{balanceCards}</div>
      </div>
    </Paper>
  );

  const EmployeeVariant = ({
    balanceCards
  }: {
    balanceCards: JSX.Element[];
  }): React.ReactElement => (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      {showHeader && <Header variant="employee" />}
      {balanceCards.map(bc => (
        <Paper key={bc.key} className="flex max-h-[80px] w-full flex-grow p-3">
          {bc}
        </Paper>
      ))}
    </div>
  );

  return (
    <ThemeLayout>
      {variant === "admin" && <AdminVariant balanceCards={balanceCards} />}
      {variant === "employee" && (
        <EmployeeVariant balanceCards={balanceCards} />
      )}
    </ThemeLayout>
  );
};

export default BalanceCard;
