import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";

import { DateTime } from "luxon";
import Dropdown from "react-bootstrap/Dropdown";

import CalenderIcon from "~/images/calendar-icon.svg";
import { MutableSessionContext } from "~/lib/context";
import { useEffectOnce } from "~/lib/hooks";

import "./DateRangeFilterDropdown.scss";

export interface DateRangeItem {
  id: number;
  value: string;
  text: string;
  subText?: string;
  dividerAfter?: boolean;
  startDate: DateTime;
  frameLength: "month" | "week" | "day" | undefined;
}

export interface DateRangeFilterProps {
  items?: DateRangeItem[];
  defaultItemValue?: string;
  disabled?: boolean;

  onDateRangeSelectionChange(
    value: string,
    frameLength: "month" | "week" | "day" | undefined,
    startDate: DateTime,
    text: string
  ): void;
}

export const DateRangeFilterDropdown: FunctionComponent<
  DateRangeFilterProps
> = props => {
  const [currentItem, setCurrentItem] = useState<DateRangeItem>();
  const { session } = useContext(MutableSessionContext);

  const optionsData = {
    lastMonth: {
      text: "Last 30 Days",
      startDate: DateTime.local().setZone("US/Pacific").minus({ days: 30 })
    },
    last12Weeks: {
      text: "Last 12 Weeks",
      startDate: DateTime.local()
        .setZone("US/Pacific")
        .minus({ weeks: 11, days: 1 })
        .startOf("week")
        .minus({ days: 1 })
    },
    last12Months: {
      text: "Last 12 Months",
      startDate: DateTime.local()
        .minus({ months: 11, days: 1 })
        .startOf("month")
    }
  };

  // TODO: (REMOVE WHEN IT'S APPROPRIATE) THIS IS A HACK FOR com_ugld44nqmnqzrwnw
  // This is a temporary solution to prevent the user from selecting a date range
  // that is outside of the current year

  const HACKY_COMPANY_ID = "com_ugld44nqmnqzrwnw";

  if (session?.company?.id === HACKY_COMPANY_ID) {
    const startOfYear = DateTime.local().setZone("US/Pacific").startOf("year");

    const getRecentDate = (date1: DateTime, date2: DateTime): DateTime =>
      date1 > date2 ? date1 : date2;

    optionsData.lastMonth.text = "Last 30 Days (Within this year)";

    optionsData.lastMonth.startDate = getRecentDate(
      optionsData.lastMonth.startDate,
      startOfYear
    );
    optionsData.last12Weeks.text = "Last 12 Weeks (Within this year)";
    optionsData.last12Weeks.startDate = getRecentDate(
      optionsData.last12Weeks.startDate,
      startOfYear
    );
    optionsData.last12Months.text = "Year to Date";
    optionsData.last12Months.startDate = getRecentDate(
      optionsData.last12Months.startDate,
      startOfYear
    );
  }

  const DEFAULT_DATE_RANGE_OPTIONS: DateRangeItem[] = [
    {
      id: 1,
      text: optionsData.lastMonth.text,
      value: "lastMonth",
      startDate: optionsData.lastMonth.startDate,
      frameLength: "day"
    },
    {
      id: 2,
      text: optionsData.last12Weeks.text,
      value: "last12Weeks",
      startDate: optionsData.last12Weeks.startDate,
      frameLength: "week"
    },
    {
      id: 3,
      text: optionsData.last12Months.text,
      value: "last12Months",
      startDate: optionsData.last12Months.startDate,
      frameLength: "month"
    }
  ];

  function generateSubText(startDate: DateTime, value: string): string {
    const currentDayYesterday = DateTime.local().minus({ days: 1 });
    try {
      return `${startDate.toLocaleString()} - ${currentDayYesterday.toLocaleString()}`;
    } catch (e) {
      return `${startDate.toLocaleString({
        locale: "en-US"
      })} - ${currentDayYesterday.toLocaleString({ locale: "en-US" })}`;
    }
  }

  useEffectOnce(() => {
    const match = DEFAULT_DATE_RANGE_OPTIONS.find(
      item => item.value === props?.defaultItemValue
    );
    setCurrentItem(match ?? DEFAULT_DATE_RANGE_OPTIONS[0]);
  });

  useEffect(() => {
    if (currentItem) {
      props.onDateRangeSelectionChange(
        currentItem.value,
        currentItem.frameLength,
        currentItem.startDate,
        currentItem.text
      );
    }
  }, [currentItem, props]);

  const handleSelect = (eventkey: any): void => {
    const selectedItem =
      DEFAULT_DATE_RANGE_OPTIONS?.find(
        ({ id }) => id.toString() === eventkey
      ) || currentItem;
    selectedItem && setCurrentItem(selectedItem);
  };

  return (
    <Dropdown onSelect={handleSelect} className="dropdown-group">
      <Dropdown.Toggle
        disabled={props?.disabled}
        id="dropdown-date-range"
        className="btn-outline-primary dropdown-toggle-button"
      >
        {currentItem?.text}
        <img
          className="h-5 m-auto w-auto calendar-icon"
          src={CalenderIcon}
          alt="TeamSense"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="overflow-hidden">
        {DEFAULT_DATE_RANGE_OPTIONS?.map(item => {
          return (
            <Dropdown.Item
              key={`dropdown-item-${item.id.toString()}`}
              eventKey={item.id.toString()}
              onSelect={handleSelect}
              className="dropdown-item"
              style={{ padding: ".35rem 0 .65rem" }}
            >
              <p className="dropdown-text">{item.text}</p>
              <p className="dropdown-sub-text">
                {generateSubText(item.startDate, item.value)}
              </p>
              {item.dividerAfter && (
                <Dropdown.Divider className="dropdown-divider" />
              )}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
