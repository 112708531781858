import * as React from "react";
import { memo, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import {
  CellPropsOverrides,
  GridCell,
  GridCellProps,
  GridColumnMenu,
  GridColumnMenuProps,
  GridFilterPanel,
  GridHeaderFilterCell,
  GridHeaderFilterCellProps,
  GridLogicOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarProps,
  ToolbarPropsOverrides
} from "@mui/x-data-grid-pro";
import { CaretRight } from "@phosphor-icons/react";

import Button from "../Button/Button";
import colors from "../Theme/colors";

import * as api from "~/api";
import {
  getLabelColor,
  getLabelColorDarkened,
  getLabelColorLightened,
  getLabelDisplay
} from "~/lib/status";
import { TSPill } from "~/ts-components/common/ts-pill/TSPill";
import Restricted from "~/ts-components/permissionProvider/Restricted";

type CustomToolbarProps = GridToolbarProps &
  ToolbarPropsOverrides & {
    toggleFilters: () => void;
    clearFilters: () => void;
    isShowHeaderFilters: boolean;
  };

const getEmployeeLink = (id: string): string =>
  `/dashboard/new-employees/employee/${id}`;

const CustomToolbar = (props: CustomToolbarProps): JSX.Element => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            variant: "text"
          }
        }}
      />
      <Button
        onClick={() => props.toggleFilters()}
        variant="text"
        sx={{ width: "110px" }}
      >
        {props.isShowHeaderFilters ? "Hide" : "Show"} Filters
      </Button>
      <GridToolbarDensitySelector
        slotProps={{
          button: {
            variant: "text"
          }
        }}
      />
      <Button onClick={() => props.clearFilters()} variant="text">
        Remove all filters
      </Button>
    </GridToolbarContainer>
  );
};

export const MemorizedToolbar = memo(
  ({ toggleFilters, isShowHeaderFilters, ...props }: CustomToolbarProps) => (
    <CustomToolbar
      {...props}
      toggleFilters={toggleFilters}
      isShowHeaderFilters={isShowHeaderFilters}
    />
  ),
  (prevProps, nextProps) =>
    prevProps.isShowHeaderFilters === nextProps.isShowHeaderFilters
);

type CustomCellRowProps = GridCellProps &
  CellPropsOverrides & { clickable?: boolean };

const onClick = (
  e: { preventDefault: () => void },
  callback: () => void
): void => {
  // Preventing the href to work to allow onRowClick to handle the click
  // So the app does an internal redirection instead of a full page reload
  e.preventDefault();
  callback();
};

const CustomCellRow = ({
  clickable = true,
  ...props
}: CustomCellRowProps): JSX.Element => {
  const history = useHistory();
  if (
    !clickable ||
    ["__check__", "team_lead"].includes(props.column.field) ||
    props.pinnedPosition
  ) {
    return <GridCell {...props} />;
  }
  const link = getEmployeeLink(`${props.rowId}`);
  const callback = (): void => history.push(link);
  return (
    <a
      href={link}
      onClick={e => onClick(e, callback)}
      target="_self"
      className="custom-cell"
    >
      <GridCell {...props} />
    </a>
  );
};

export const MemorizedCellRow = memo(
  (props: CustomCellRowProps) => <CustomCellRow {...props} />,
  (prevProps, nextProps) => {
    if (prevProps.column.field === "_arrow") {
      return false;
    }
    return (
      prevProps.rowId === nextProps.rowId &&
      prevProps.width === nextProps.width &&
      prevProps.colIndex === nextProps.colIndex
    );
  }
);

type CustomCellLinkProps = { id: string; name: string };

const CustomCellLink = ({ id, name }: CustomCellLinkProps): JSX.Element => {
  const history = useHistory();
  const link = getEmployeeLink(id);
  const callback = (): void => history.push(link);
  return (
    <a
      href={link}
      onClick={e => onClick(e, callback)}
      target="_self"
      className="custom-cell"
    >
      {name}
    </a>
  );
};

export const MemorizedCellLink = memo(
  (props: CustomCellLinkProps) => <CustomCellLink {...props} />,
  (prevProps, nextProps) => prevProps.id === nextProps.id
);

const CustomColumnMenu = (props: GridColumnMenuProps): JSX.Element => {
  return <GridColumnMenu {...props} slots={{ columnMenuFilterItem: null }} />;
};

export const MemorizedColumnMenu = memo(
  (props: GridColumnMenuProps) => <CustomColumnMenu {...props} />,
  () => true
);

type CustomHeaderFilterCellProps = GridHeaderFilterCellProps & {
  show: boolean;
  firstRender: boolean;
  colDef: GridHeaderFilterCellProps["colDef"] & { computedWidth: number };
};

const CustomHeaderFilterCell = ({
  show,
  firstRender,
  ...props
}: CustomHeaderFilterCellProps): JSX.Element => {
  const [showAnimation, setShowAnimation] = useState(!firstRender && !show);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowAnimation(show);
    }, 0);
  }, [show]);

  return (
    <Collapse in={showAnimation}>
      <GridHeaderFilterCell {...props} />
    </Collapse>
  );
};

export const MemorizedHeaderFilterCell = memo(
  (props: CustomHeaderFilterCellProps) => <CustomHeaderFilterCell {...props} />,
  (prevProps, nextProps) =>
    prevProps.show === nextProps.show &&
    prevProps.colDef.computedWidth === nextProps.colDef.computedWidth
);

const RightArrow = (): JSX.Element => (
  <div
    style={{
      cursor: "pointer",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <CaretRight size={20} color={colors.teal[40]} />
  </div>
);

export const MemorizedRightArrow = memo(
  () => <RightArrow />,
  () => true
);

type StatusPillsProps = {
  labels: api.LabelInfoMap;
  language: string;
  activeEvents: api.EmployeeEvent[];
  employee: api.Employee;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => void;
};

const StatusPills = ({
  activeEvents,
  labels,
  language,
  employee,
  onEditStatusClick
}: StatusPillsProps): JSX.Element => {
  const statusPills = activeEvents.map(({ label: { name } }) => (
    <Restricted
      to={[
        "EmployeeHealthStatus.READ",
        "EmployeeHealthStatus.UPDATE",
        "EmployeeHealthStatus.CREATE",
        "EmployeeHealthStatus.DELETE"
      ]}
      key={name}
      disabled
    >
      <TSPill
        key={name}
        text={getLabelDisplay(name, labels, language)}
        background={getLabelColorLightened(name, labels)}
        color={getLabelColorDarkened(name, labels)}
        backgroundHover={getLabelColor(name, labels)}
        button
        onClick={event => {
          event.stopPropagation();
          onEditStatusClick?.(employee, name);
        }}
      />
    </Restricted>
  ));
  return <>{statusPills}</>;
};

export const MemorizedStatusPills = memo(
  (props: StatusPillsProps) => <StatusPills {...props} />,
  () => true
);

type ReactivateButtonProps = {
  employee: api.Employee;
  onReactivateClick?: (employee: api.Employee) => void;
};

const ReactivateButton = ({
  employee,
  onReactivateClick
}: ReactivateButtonProps): JSX.Element => {
  if (!employee) {
    return <td />;
  }
  return (
    <Restricted to="EmployeeReactivate.CREATE">
      <td className="flex w-full h-full justify-center items-center">
        <Button variant="text" onClick={() => onReactivateClick?.(employee)}>
          Reactivate
        </Button>
      </td>
    </Restricted>
  );
};

export const MemorizedReactivateButton = memo(
  (props: ReactivateButtonProps) => <ReactivateButton {...props} />,
  () => true
);

const NoRows = (): JSX.Element => (
  <>
    <div className="flex flex-col gap-2 justify-center items-center">
      <Typography variant="h1" className="text-ts-gray-20">
        No results found
      </Typography>
      <Typography variant="h3" className="text-ts-gray-20">
        We couldn't find any records that met your criteria.
      </Typography>
      <Typography variant="h3" className="text-ts-gray-20">
        Try other filters or a different search.
      </Typography>
    </div>
  </>
);

export const MemorizedNoRows = memo(
  () => <NoRows />,
  () => true
);

export const CustomPanelFilter = ({
  showAddFilterButton,
  showRemoveAllButton,
  selectedFilters
}: {
  showAddFilterButton: boolean;
  showRemoveAllButton: boolean;
  selectedFilters: string[];
}): JSX.Element => {
  const hideFilterOptions = (): void => {
    const filterFields = ["active_events", "division", "team_lead", "groups"];

    filterFields.forEach(field => {
      const visibility = !selectedFilters.includes(field);
      const visibilityClass = `hide-${field}-option`;
      const { classList } = document.body;
      if (visibility && classList.contains(visibilityClass)) {
        classList.remove(visibilityClass);
      }
      if (!visibility && !classList.contains(visibilityClass)) {
        classList.add(visibilityClass);
      }
    });
  };

  hideFilterOptions();

  return (
    <GridFilterPanel
      sx={{
        "& .MuiSelect-select": {
          color: colors.gray[10],
          fontWeight: "500 !important",
          fontSize: "1rem !important",
          "&:focus": {
            backgroundColor: colors.white[0]
          }
        },
        "& .MuiFormLabel-root": {
          color: colors.gray[30],
          fontSize: "0.75rem !important",
          transform: "none !important",
          lineHeight: "1rem !important"
        },
        "& .MuiButton-root": {
          color: colors.teal[40],
          backgroundColor: colors.white[0],
          "&:nth-of-type(1)": {
            color: showAddFilterButton ? colors.teal[40] : colors.red[20]
          },
          "&:nth-of-type(2)": {
            color: colors.red[20]
          }
        },
        "& .MuiDataGrid-filterFormLogicOperatorInput": {
          display: "none"
        },
        "& .MuiChip-root": {
          backgroundColor: colors.teal[90]
        },
        "& .MuiChip-label": {
          lineHeight: "1rem"
        }
      }}
      disableAddFilterButton={!showAddFilterButton}
      disableRemoveAllButton={!showRemoveAllButton}
      logicOperators={[GridLogicOperator.And]}
    />
  );
};
