import React, { ChangeEvent, useState } from "react";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { default as MuiMenuItem, MenuItemProps } from "@mui/material/MenuItem";
import {
  GridTableRowsIcon,
  GridViewHeadlineIcon,
  GridViewStreamIcon
} from "@mui/x-data-grid-pro";
import {
  Confetti,
  DotsThreeOutlineVertical,
  Funnel
} from "@phosphor-icons/react";

import Button from "~/mui-components/Button/Button";
import { ExternalActionsObj } from "~/mui-components/EmployeesTable/EmployeesTable";
import Menu from "~/mui-components/Menu/Menu";
import Switch from "~/mui-components/Switch/Switch";
import colors from "~/mui-components/Theme/colors";
import Typography from "~/mui-components/Typography/Typography";
import { TSInput } from "~common";

type EmployeesTableHeaderMuiGridProps = {
  muiGridApiRef: ExternalActionsObj | undefined;
  searchText: string;
  inactiveOnly: boolean;
  editGroupsDisabled: boolean;
  editGroupsHidden: boolean;
  setInactiveOnly: (value: boolean) => void;
  setShowCreateEmployeeModal: (value: boolean) => void;
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickAddToGroups: () => void;
  onClickRemoveFromGroup: () => void;
};

const MenuItem = (props: MenuItemProps): React.ReactElement<MenuItemProps> => (
  <MuiMenuItem
    sx={{
      color: colors.gray[30],
      "&:hover": {
        backgroundColor: colors.teal[90]
      }
    }}
    {...props}
  />
);

export const EmployeesTableHeaderMuiGrid = ({
  muiGridApiRef,
  searchText,
  inactiveOnly,
  editGroupsDisabled,
  editGroupsHidden,
  setInactiveOnly,
  handleSearchChange,
  setShowCreateEmployeeModal,
  onClickAddToGroups,
  onClickRemoveFromGroup
}: EmployeesTableHeaderMuiGridProps): JSX.Element => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  return (
    <div className="flex flex-row justify-between mb-3 items-center flex-wrap gap-3">
      <h1 className="m-0">Employees</h1>

      <div className="flex flex-row h-10 gap-3">
        <Button
          color="secondary"
          className="!min-w-24"
          onClick={() => muiGridApiRef?.showFilterPanel()}
          endIcon={
            <Funnel
              size={20}
              color={colors.teal[30]}
              weight={muiGridApiRef?.areFiltersApplied() ? "fill" : "regular"}
            />
          }
        >
          Filter
        </Button>

        <TSInput
          id="search-employees"
          defaultValue={searchText}
          type="search"
          placeholder="Search"
          onChange={handleSearchChange}
        />

        <Button
          className="!min-w-36"
          onClick={() => setShowCreateEmployeeModal(true)}
          disabled={inactiveOnly}
        >
          Add Employee
        </Button>

        <IconButton
          aria-describedby="3-dots-menu"
          onClick={e => setAnchor(e.currentTarget)}
        >
          <DotsThreeOutlineVertical
            weight="fill"
            size={20}
            color={colors.teal[40]}
          />
        </IconButton>

        <Menu
          id="3-dots-menu"
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          sx={{
            top: "16px",
            "& .MuiPaper-root": {
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)"
            }
          }}
        >
          {!editGroupsHidden && (
            <MenuItem
              disabled={editGroupsDisabled}
              onClick={() => {
                setAnchor(null);
                onClickAddToGroups();
              }}
            >
              Add Selected to Groups
            </MenuItem>
          )}

          {!editGroupsHidden && (
            <MenuItem
              disabled={editGroupsDisabled}
              onClick={() => {
                setAnchor(null);
                onClickRemoveFromGroup();
              }}
            >
              Remove Selected from Groups
            </MenuItem>
          )}

          {!editGroupsHidden && <Divider component="li" />}
          <MenuItem
            onClick={() => {
              setAnchor(null);
              setInactiveOnly(!inactiveOnly);
            }}
          >{`View ${
            inactiveOnly ? "Active" : "Deactivated"
          } Employees`}</MenuItem>
          <MenuItem
            onClick={() => {
              setAnchor(null);
              muiGridApiRef?.showColumnManagerPanel();
            }}
          >
            Manage Visible Columns
          </MenuItem>
          <Divider component="li" />
          <MuiMenuItem disabled className="!pb-0 !opacity-100 !text-ts-gray-30">
            <Typography variant="caption">Adjust Density</Typography>
          </MuiMenuItem>
          <MenuItem
            onClick={() => {
              setAnchor(null);
              muiGridApiRef?.setDensityCompact();
            }}
          >
            <ListItemIcon>
              <GridViewHeadlineIcon />
            </ListItemIcon>
            <ListItemText>Compact</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchor(null);
              muiGridApiRef?.setDensityStandard();
            }}
          >
            <ListItemIcon>
              <GridTableRowsIcon />
            </ListItemIcon>
            <ListItemText>Standard</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchor(null);
              muiGridApiRef?.setDensityComfortable();
            }}
          >
            <ListItemIcon>
              <GridViewStreamIcon />
            </ListItemIcon>
            <ListItemText>Comfortable</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export const NewTableBanner = ({
  isToogleOn,
  setToogle
}: {
  isToogleOn: boolean;
  setToogle: (value: boolean) => void;
}): JSX.Element => (
  <div className="flex flex-row flex-grow justify-between items-center w-full py-1 px-2 rounded-lg h-12 min-h-12 bg-ts-blue-20 text-white mb-4">
    <div className="flex flex-row items-center gap-3">
      <Confetti size={24} />
      <Typography variant="h3" className="!m-0">
        Feature Announcement: Employees table has new sorting and filtering
      </Typography>
    </div>
    <div className="flex flex-row items-center gap-3">
      <Typography variant="h3" className="!m-0">
        {isToogleOn ? "Back to Classic Table" : "Try New Table"}
      </Typography>
      <Switch
        checked={isToogleOn}
        onChange={e => setToogle(e.target.checked)}
      />
    </div>
  </div>
);
