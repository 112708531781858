import React, { ChangeEvent, FC, useCallback, useMemo } from "react";
import { Row } from "react-bootstrap";

import clsx from "clsx";

import Restricted from "../permissionProvider/Restricted";

import {
  FormData,
  TeamLeadSelectItemOption,
  FormErrors
} from "./EditEmployeeModal";

import * as api from "~/api";
import { employeeSearchHandler } from "~/lib/typeaheadHandler";
import { TSInput, TSTypeahead, TSTypeaheadOption } from "~common";

// This component was separated from the EditEmployeeModal.tsx in order to enforce permission checks
// If changes are made to the structure of this component, validate permissions still work as expected
export const OrganizationInputs: FC<OrganizationInputsProps> = props => {
  const {
    session,
    formData,
    formErrors,
    employee,
    tsInputChangeHandler,
    typeaheadChangeHandler,
    allGroups,
    showDivisions,
    disabled
  } = props;

  ///////////////////////////////
  // TEAM LEAD OPTION METHODS //
  /////////////////////////////

  const defaultTeamLeadOption = useMemo((): TSTypeaheadOption[] | undefined => {
    return formData?.teamLead
      ? [
          {
            label: formData.teamLead.name,
            value: formData.teamLead.id
          }
        ]
      : [];
  }, [formData]);

  //////////////////////////////
  // DIVISION OPTION METHODS //
  ////////////////////////////

  const divisionOptions = useMemo((): TSTypeaheadOption[] => {
    return (
      session.divisions?.map(value => ({
        label: value.name,
        value: value.id
      })) || []
    );
  }, [session.divisions]);

  // Retrieve a division option by id
  const getDivisionOption = useCallback(
    (id: string | undefined): TSTypeaheadOption[] | undefined => {
      if (id === undefined) {
        return;
      }

      const divisionMatch = session.divisions?.find(p => p.id === id);
      return divisionMatch
        ? [{ label: divisionMatch.name, value: divisionMatch.id }]
        : [];
    },
    [session.divisions]
  );

  ///////////////////////////
  // GROUP OPTION METHODS //
  /////////////////////////

  const getGroupOptions = useCallback(
    (items: api.Group[] | undefined): TSTypeaheadOption[] | undefined => {
      if (items === undefined) {
        return;
      }

      const groupOptions: TSTypeaheadOption[] = items
        .filter(item => {
          return allGroups?.find(p => p.id === item.id);
        })
        .map(item => {
          return { label: item.name, value: item.id };
        });

      return groupOptions || [];
    },
    [allGroups]
  );

  const groupOptions = useMemo((): TSTypeaheadOption[] => {
    return (
      allGroups?.map((item: api.Group) => ({
        label: item.name,
        value: item.id
      })) || []
    );
  }, [allGroups]);

  //////////////////////
  // CHANGE HANDLERS //
  ////////////////////

  const teamLeadTypeaheadChangeHandler = (
    selected: TeamLeadSelectItemOption[]
  ): void => {
    if (session.company) {
      delete formData?.teamLead;

      const selectedLead =
        (selected.length > 0 && {
          name: selected[0].label,
          id: selected[0].id,
          company_id: session.company.id
        }) ||
        undefined;

      typeaheadChangeHandler<api.TeamLead | undefined>(
        "teamLead",
        selectedLead
      );
    }
  };

  const divisionTypeaheadChangeHandler = (
    selected: TSTypeaheadOption[]
  ): void => {
    formData.divisionId = "";

    typeaheadChangeHandler<string>(
      "divisionId",
      selected && selected[0]?.value
    );
  };

  const groupsTypeaheadChangeHandler = (
    selected: TSTypeaheadOption[]
  ): void => {
    delete formData?.groups;

    const selectedGroups =
      (selected.length > 0 &&
        allGroups?.filter(item => {
          return selected.findIndex(p => p.value === item.id) > -1;
        })) ||
      undefined;

    typeaheadChangeHandler<api.Group[] | undefined>("groups", selectedGroups);
  };

  const EmployeeId = (): JSX.Element => (
    <TSInput
      groupClass="col-sm-6"
      type="text"
      id="externalId"
      label="Employee ID"
      onChange={tsInputChangeHandler}
      defaultValue={formData?.externalId || ""}
      isInvalid={Boolean(formErrors?.externalId)}
      errorText={formErrors?.externalId}
      disabled={(employee && employee?.id !== undefined) || disabled}
      required={!employee}
      noValidate={true}
      helpText="Unique for this employee and cannot be changed"
    />
  );

  const BadgeId = (): JSX.Element => (
    <TSInput
      groupClass="col-sm-6"
      type="text"
      id="badgeId"
      label="Badge ID"
      onChange={tsInputChangeHandler}
      defaultValue={formData?.badgeId || ""}
      isInvalid={Boolean(formErrors?.badgeId)}
      errorText={formErrors?.badgeId}
      required={false}
      helpText="Optional additional ID for this employee"
    />
  );

  const Division = (): JSX.Element => {
    if (showDivisions) {
      return (
        <TSTypeahead
          className="col-sm-6"
          id="divisionId"
          labelKey="label"
          label="Division"
          onChange={selected =>
            divisionTypeaheadChangeHandler(selected as TSTypeaheadOption[])
          }
          options={divisionOptions}
          placeholder="Choose a Division"
          defaultSelected={getDivisionOption(formData?.divisionId)}
          errorText={formErrors?.divisionId}
          isInvalid={Boolean(formErrors?.divisionId)}
          readOnly={disabled}
          required
        />
      );
    }
    return <></>;
  };

  const Manager = (): JSX.Element => (
    <TSTypeahead
      id="teamLead"
      label="Manager"
      labelKey="label"
      onChange={selected =>
        teamLeadTypeaheadChangeHandler(selected as TeamLeadSelectItemOption[])
      }
      showId
      options={[]}
      placeholder="Search for a manager"
      defaultSelected={defaultTeamLeadOption}
      errorText={formErrors?.teamLead}
      isInvalid={Boolean(formErrors?.teamLead)}
      asyncSearchHandler={nameOrId =>
        employeeSearchHandler(session, { nameOrId })
      }
      className="col-sm-6 async-search"
      readOnly={disabled}
    />
  );

  const Groups = ({ className }: { className: string }): JSX.Element => (
    <Restricted
      to={["EmployeeCreate.CREATE", "EmployeeOrgInfo.UPDATE"]}
      disabled
    >
      <TSTypeahead
        className={clsx(disabled && "disable-by-permission", className)}
        id="groups"
        label="Groups"
        labelKey="label"
        multiple
        onChange={selected =>
          groupsTypeaheadChangeHandler(selected as TSTypeaheadOption[])
        }
        options={groupOptions}
        placeholder="Choose groups"
        defaultSelected={getGroupOptions(formData?.groups)}
        readOnly={disabled}
      />
    </Restricted>
  );

  return (
    <>
      <h1>Organizational information</h1>
      <Row className="mb-0">
        <EmployeeId />
        <BadgeId />
      </Row>
      <Row>
        <Division />
        <Manager />
      </Row>
      <Row>
        <Groups className="col-sm-12" />
      </Row>
    </>
  );
};

type OrganizationInputsProps = {
  session: api.Session;
  formData: FormData;
  disabled?: boolean;
  formErrors?: FormErrors;
  tsInputChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
  typeaheadChangeHandler: <T>(name: string, value: T) => void;
  employee?: api.Employee;
  allGroups?: api.Group[];
  showDivisions?: boolean;
};
