import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid-pro";

import { EmployeeFilterActions, FilterListName } from "~/lib/employeesList";

// Helpers

export const getIdsFromFilterExcludingNone = (
  filter: string | string[]
): string[] => {
  if (Array.isArray(filter)) {
    return filter.filter(item => item !== "none");
  }
  return filter === "none" ? [] : [filter];
};

export const isFilterNone = (filter: string | string[]): boolean => {
  return Array.isArray(filter) ? filter.includes("none") : filter === "none";
};

const generateRandomId = (): number => Math.floor(Math.random() * 10000);

const defaultOperator = "isAnyOf";

const defaultFirstFilter = {
  field: "active_events",
  operator: defaultOperator,
  value: [],
  id: generateRandomId()
};

// Filter Actions

export enum FilterAction {
  CLEAR_ALL = "CLEAR_ALL",
  ADD_FILTER = "ADD_FILTER",
  REMOVE_FILTER = "REMOVE_FILTER",
  REPLACE_FILTER = "REPLACE_FILTER",
  CLEAR_FITLER = "CLEAR_FITLER",
  UPDATE_FILTER = "UPDATE_FILTER",
  NO_ACTION = "NO_ACTION"
}

const clearAllFilters = (
  filterModel: GridFilterModel | undefined
): GridFilterModel => {
  return { ...filterModel, items: [] };
};

const noActionFilter = (
  filterModel: GridFilterModel | undefined
): GridFilterModel => filterModel ?? { items: [] };

const removeFilter = (
  filterModel: GridFilterModel | undefined,
  filter: GridFilterModel["items"][number]["field"]
): GridFilterModel => ({
  ...filterModel,
  items: filterModel?.items?.filter(item => item.field !== filter) || []
});

const addFilter = (
  filterModel: GridFilterModel | undefined,
  filter: GridFilterModel["items"][number]["field"]
): GridFilterModel => ({
  ...filterModel,
  items: [
    ...(filterModel?.items || []),
    {
      field: filter,
      operator: defaultOperator,
      value: [],
      id: generateRandomId()
    }
  ]
});

const clearFilter = (
  filterModel: GridFilterModel | undefined,
  filter: GridFilterModel["items"][number]["field"]
): GridFilterModel => {
  return {
    ...(filterModel ?? { items: [] }),
    items: filterModel?.items?.filter(i => i.field !== filter) || []
  };
};

const updateFilter = (
  filterModel: GridFilterModel | undefined,
  newFilterModel: GridFilterModel
): GridFilterModel => {
  const newItems: GridFilterModel["items"] = [...(filterModel?.items || [])];
  newFilterModel.items?.forEach(item => {
    const index = newItems.findIndex(newItem => newItem.field === item.field);
    if (index === -1) {
      newItems.push(item);
    } else {
      newItems[index] = item;
    }
  });
  return { ...filterModel, items: newItems };
};

const replaceFilter = (
  filterModel: GridFilterModel | undefined,
  filter1: GridFilterModel["items"][number]["field"],
  filter2: GridFilterModel["items"][number]["field"],
  operator: GridFilterModel["items"][number]["operator"] | undefined
): GridFilterModel => {
  const filterIndex = filterModel?.items?.findIndex(
    item => item.field === filter1
  );
  if (filterIndex === undefined || filterIndex === -1) {
    return filterModel || { items: [] };
  }
  const newFilterModel = {
    ...filterModel,
    items: [...(filterModel?.items || [])]
  };
  newFilterModel.items[filterIndex] = {
    field: filter2,
    operator: operator || defaultOperator,
    value: [],
    id: generateRandomId()
  };
  return newFilterModel;
};

const removeDuplicatedItems = (
  filterModel: GridFilterModel
): GridFilterModel => {
  const newItems: GridFilterModel["items"] = [];
  const seen: Record<string, boolean> = {};
  filterModel.items.forEach(item => {
    if (!seen[item.field]) {
      seen[item.field] = true;
      newItems.push(item);
    }
  });
  return { ...filterModel, items: newItems };
};

// Conditions

interface Condition {
  (props: {
    lastChange: GridFilterItem | undefined;
    filterModel: GridFilterModel | undefined;
    newFilterModel: GridFilterModel;
    availableFilters?: string[];
  }): boolean;
}
const hasNoChanges: Condition = props =>
  !props.lastChange && props.newFilterModel.items?.length === 0;

const isLastChangeValid: Condition = props =>
  Boolean(props.lastChange) &&
  props.lastChange?.value !== undefined &&
  props.lastChange?.value !== "" &&
  props.lastChange?.value.length !== 0;

const isFilterRemoved: Condition = props =>
  props.newFilterModel.items?.length -
    (props.filterModel?.items?.length ?? NaN) ===
  -1;

const isFilterAdded: Condition = props =>
  props.newFilterModel.items?.length -
    (props.filterModel?.items?.length ?? NaN) ===
  1;

const areMultipleFiltersAdded: Condition = props =>
  props.newFilterModel.items?.length -
    (props.filterModel?.items?.length ?? NaN) >
  1;

const isFilterReplaced: Condition = props =>
  Boolean(props.lastChange) &&
  props.filterModel?.items?.length === props.newFilterModel?.items?.length &&
  props.newFilterModel?.items?.filter(
    f => !props.filterModel?.items?.map(f => f.field).includes(f.field)
  ).length === 1;

const isOperatorChanged: Condition = props =>
  props.filterModel?.items?.length === props.newFilterModel?.items?.length &&
  props.newFilterModel?.items?.filter(
    f1 =>
      f1.operator !==
      props.filterModel?.items.find(f2 => f1.field === f2.field)?.operator
  ).length === 1;

const isClearAll: Condition = props =>
  props.newFilterModel.items?.length === 0 &&
  props.filterModel?.items?.length !== 0;

const isFirstFilterEver: Condition = props =>
  props.filterModel?.items?.length === 1 &&
  props.newFilterModel?.items?.length === 1 &&
  props.filterModel?.items[0].field === defaultFirstFilter.field &&
  props.newFilterModel?.items[0].field === defaultFirstFilter.field &&
  props.filterModel?.items[0].operator === defaultFirstFilter.operator &&
  props.newFilterModel?.items[0].operator === defaultFirstFilter.operator &&
  Array.isArray(props.filterModel?.items[0].value) &&
  props.filterModel?.items[0].value.length === 0 &&
  !("value" in props.newFilterModel.items[0]);

const isAnyOf: Condition = props => props.lastChange?.operator === "isAnyOf";

const isIs: Condition = props => props.lastChange?.operator === "is";

// Filter logic

export const lastModelChange = (
  filterModel: GridFilterModel | undefined,
  newFilterModel: GridFilterModel
): GridFilterItem | undefined => {
  const lastChange = newFilterModel.items?.find(item => {
    const existingItem = filterModel?.items?.find(
      currentItem => currentItem.field === item.field
    );
    return !existingItem || existingItem.value !== item.value;
  });
  return lastChange;
};

export const callFilterAction = (
  filterModel: GridFilterModel | undefined,
  newFilterModel: GridFilterModel,
  dispatch: (value: EmployeeFilterActions) => void,
  deactivatedMode: boolean
): GridFilterModel => {
  const lastChange = lastModelChange(filterModel, newFilterModel);
  const conditionProps = {
    lastChange,
    filterModel: filterModel || { items: [] },
    newFilterModel: newFilterModel || { items: [] }
  };
  const dispatchKeyByStatus: Record<
    "active" | "inactive",
    Record<string, FilterListName>
  > = {
    active: {
      active_events: "activeStatuses",
      division: "activeDivisions",
      groups: "activeGroups",
      team_lead: "activeManagers"
    },
    inactive: {
      division: "activeDivisions",
      team_lead: "activeManagers"
    }
  };
  const dispatchKey: Record<string, FilterListName> =
    dispatchKeyByStatus[deactivatedMode ? "inactive" : "active"];
  const availableFilters = Object.keys(dispatchKey);

  if (deactivatedMode) {
    filterModel = {
      ...filterModel,
      items:
        filterModel?.items?.filter(item =>
          availableFilters.includes(item.field)
        ) || []
    };
    newFilterModel = {
      ...newFilterModel,
      items:
        newFilterModel.items?.filter(item =>
          availableFilters.includes(item.field)
        ) || []
    };
  }

  if (areMultipleFiltersAdded(conditionProps)) {
    newFilterModel = removeDuplicatedItems(newFilterModel);
    return noActionFilter(newFilterModel);
  }

  if (isFirstFilterEver(conditionProps)) {
    return noActionFilter(filterModel);
  }

  if (isClearAll(conditionProps)) {
    dispatch({ type: "clearAllFilters" });
    const partialNewFilterModel = clearAllFilters(filterModel);
    return callFilterAction(
      partialNewFilterModel,
      { ...partialNewFilterModel, items: [defaultFirstFilter] },
      dispatch,
      deactivatedMode
    );
  }

  if (hasNoChanges(conditionProps)) {
    dispatch({ type: "clearAllFilters" });
    return clearAllFilters(filterModel);
  }

  if (isFilterRemoved(conditionProps)) {
    const missingFilter = filterModel?.items?.find(
      item =>
        !newFilterModel.items?.find(newItem => newItem.field === item.field)
    );
    if (missingFilter && availableFilters.includes(missingFilter.field)) {
      dispatch({
        type: "clearFilter",
        listName: dispatchKey[missingFilter.field]
      });
      return removeFilter(filterModel, missingFilter.field);
    }
    return noActionFilter(filterModel);
  }

  if (isFilterAdded(conditionProps)) {
    const newFilter = availableFilters.find(
      filter => !filterModel?.items?.find(item => item.field === filter)
    );
    if (newFilter) {
      if (isLastChangeValid(conditionProps)) {
        // This code block is to address when a filter is added with a value
        // So this will first adding the filter and call the function again
        // to update the filter value
        const partialNewFilterModel = addFilter(filterModel, newFilter);
        const nextNewFilterModel = updateFilter(
          partialNewFilterModel,
          newFilterModel
        );
        return callFilterAction(
          partialNewFilterModel,
          nextNewFilterModel,
          dispatch,
          deactivatedMode
        );
      }
      return addFilter(filterModel, newFilter);
    }
    return noActionFilter(filterModel);
  }

  if (isFilterReplaced(conditionProps)) {
    const prevItems = filterModel?.items?.map(i => i.field) || [];
    const nextItems = newFilterModel?.items?.map(i => i.field) || [];
    const replaced = prevItems.find(i => !nextItems.includes(i));
    const replacedBy = nextItems.find(i => !prevItems.includes(i));
    if (replaced && replacedBy) {
      const operator = newFilterModel?.items?.find(
        f => f.field === replacedBy
      )?.operator;
      dispatch({ type: "clearFilter", listName: dispatchKey[replaced] });
      return replaceFilter(filterModel, replaced, replacedBy, operator);
    }
    return noActionFilter(filterModel);
  }

  if (isOperatorChanged(conditionProps)) {
    const filter = newFilterModel?.items?.find(
      f1 =>
        f1.operator !==
        filterModel?.items.find(f2 => f1.field === f2.field)?.operator
    );
    if (filter?.field) {
      const operator = newFilterModel?.items?.find(
        f => f.field === filter.field
      )?.operator;
      dispatch({ type: "clearFilter", listName: dispatchKey[filter.field] });
      return replaceFilter(filterModel, filter.field, filter.field, operator);
    }
    return noActionFilter(filterModel);
  }

  if (!lastChange) {
    return noActionFilter(filterModel);
  }

  const listName = dispatchKey[lastChange?.field];

  if (isAnyOf(conditionProps)) {
    if (lastChange.value === undefined || lastChange.value?.length === 0) {
      dispatch({ type: "clearFilter", listName });
      return clearFilter(filterModel, lastChange.field);
    }
    dispatch({
      type: "updateVisibleByFilterItems",
      listName,
      items: getIdsFromFilterExcludingNone(lastChange.value),
      displayEmpty: isFilterNone(lastChange.value)
    });
    return updateFilter(filterModel, newFilterModel);
  }
  if (isIs(conditionProps)) {
    if (lastChange.value === undefined) {
      dispatch({ type: "clearFilter", listName });
      return clearFilter(filterModel, lastChange.field);
    }

    if (Array.isArray(lastChange.value) && lastChange.value.length === 0) {
      return noActionFilter(filterModel);
    }
    dispatch({
      type: "updateVisibleByFilterItems",
      listName,
      items: getIdsFromFilterExcludingNone(lastChange.value),
      displayEmpty: isFilterNone(lastChange.value)
    });
    return updateFilter(filterModel, newFilterModel);
  }
  return noActionFilter(filterModel);
};
