import * as React from "react";

import Link, { LinkProps } from "@mui/material/Link";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";

const ButtonTextLink = (
  componentProps: LinkProps & {
    onNewTab?: boolean;
    startIcon?: JSX.Element | string;
    endIcon?: JSX.Element | string;
  }
): React.ReactElement<LinkProps> => {
  const { children, className, props } = adaptProps(componentProps);
  const newTabProps = { target: "_blank", rel: "noopener noreferrer" };
  return (
    <ThemeLayout>
      <Link
        className={className}
        {...(componentProps.onNewTab ? newTabProps : {})}
        {...props}
      >
        {componentProps.startIcon}
        {children}
        {componentProps.endIcon}
      </Link>
    </ThemeLayout>
  );
};

export default ButtonTextLink;
