import * as React from "react";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApiPro,
  GridCellProps,
  GridColDef,
  GridColumnMenuProps,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridPreferencePanelsValue,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import {
  CustomPanelFilter,
  MemorizedCellRow,
  MemorizedColumnMenu,
  MemorizedNoRows
} from "./CustomGridSlots";
import DataGrid from "./DataGrid";
import {
  callFilterAction,
  getIdsFromFilterExcludingNone,
  isFilterNone
} from "./filters";
import gridHeaders, { EmployeeTableHeaders } from "./GridHeaders";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/mutableSession";
import {
  EmployeeDisplayList,
  EmployeeFilterActions
} from "~/lib/employeesList";
import { FilterListItem, SessionKey } from "~/lib/filterList";
import { useLinguiLanguage } from "~/lib/hooks";

export const STORAGE_KEY = "dataGridState";

export type ExternalActionsObj = {
  showFilterPanel: () => void;
  showColumnManagerPanel: () => void;
  setDensityCompact: () => void;
  setDensityStandard: () => void;
  setDensityComfortable: () => void;
  areFiltersApplied: () => boolean;
};

type EmployeesTableProps = {
  deactivatedMode: boolean;
  displayList: EmployeeDisplayList;
  dispatch: (value: EmployeeFilterActions) => void;
  searchText?: string;
  groups: api.Group[];
  managers: api.IdentificationEmployee[];
  statuses: FilterListItem[];
  divisions: FilterListItem[];
  // onRequestAllEmployeeIds?: () => any;
  onSelectEmployees?: (selectedEmployeeIds: api.Employee["id"][]) => void;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => void;
  onReactivateClick?: (employee: api.Employee) => void;
  onPageChange?: (page: number, pageSize: number) => void;
  onSortChange?: (order: string) => void;
  isLoading?: boolean;
  initPage?: number;
  initPageSize?: number;
  storageType?: "LOCAL" | "SESSION";
  setExternalActionsObj?: (externalActionObject: ExternalActionsObj) => void;
};

const getStoragedState = (storage: Storage): GridInitialStatePro => {
  const gridData = storage?.getItem(STORAGE_KEY);
  if (gridData) {
    try {
      return JSON.parse(gridData);
    } catch (e) {
      console.error(e);
    }
  }
  return {};
};

const sortModelToString = (sortModel: GridSortModel): string => {
  return sortModel
    .map((prop: GridSortItem) => {
      return `${prop.sort === "asc" ? "" : "-"}${prop.field}`;
    })
    .join(",")
    .replaceAll("team_lead", "team_lead__name")
    .replaceAll("division", "division__name");
};

const saveSnapshotWithCompatibility = (
  state: GridInitialStatePro,
  storage: Storage
): void => {
  const search: string | undefined =
    state.filter?.filterModel?.quickFilterValues?.[0];
  if (search) {
    storage.setItem(SessionKey.employeesSearchFilter, search);
  }
  const sorting = state.sorting?.sortModel;
  if (sorting) {
    storage.setItem(SessionKey.employeesSorting, sortModelToString(sorting));
  }
  const filters = [
    {
      filterKey: "division",
      storageKey: SessionKey.employeesDivisionFilter
    },
    {
      filterKey: "team_lead",
      storageKey: SessionKey.employeesManagerFilter
    },
    {
      filterKey: "status",
      storageKey: SessionKey.employeesStatusFilter
    },
    {
      filterKey: "groups",
      storageKey: SessionKey.employeesGroupFilter
    }
  ];
  filters.forEach(({ filterKey, storageKey }) => {
    const filter: string[] = state.filter?.filterModel?.items?.find(
      item => item.field === filterKey
    )?.value;
    if (filter) {
      storage.setItem(
        storageKey,
        JSON.stringify({
          ids: getIdsFromFilterExcludingNone(filter),
          none: isFilterNone(filter)
        })
      );
    }
  });
};

const showFilterPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined
): void => {
  const columnField =
    filterModel?.items?.[0]?.field || EmployeeTableHeaders.Division;
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showFilterPanel(columnField);
};

const showColumnManagerPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined
): void => {
  const columnField =
    filterModel?.items?.[0]?.field || EmployeeTableHeaders.Division;
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
};

const setTableDensity = (
  apiRef: React.MutableRefObject<GridApiPro>,
  density: GridDensity
): void => {
  if (!apiRef.current) {
    return;
  }
  apiRef.current.setDensity(density);
};

const areFiltersApplied = (
  apiRef: React.MutableRefObject<GridApiPro>
): boolean => {
  if (!apiRef.current) {
    return false;
  }
  return apiRef.current.state.filter?.filterModel?.items.some(
    item => item.value !== undefined && item.value.length > 0
  );
};

const EmployeesTable = ({
  displayList,
  deactivatedMode,
  searchText,
  managers,
  groups,
  statuses,
  divisions,
  isLoading,
  onSelectEmployees,
  onPageChange,
  onSortChange,
  dispatch,
  onEditStatusClick,
  onReactivateClick,
  initPage = 0,
  initPageSize = 100,
  storageType = "SESSION",
  setExternalActionsObj
}: EmployeesTableProps): JSX.Element => {
  const apiRef = useGridApiRef();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();

  const rows = useMemo(() => displayList.employees, [displayList.employees]);
  const rowCount = useMemo(() => displayList.count || 0, [displayList.count]);

  const [paginationModel, setPaginationModel] = useState({
    page: initPage,
    pageSize: initPageSize
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();

  const storage = { LOCAL: localStorage, SESSION: sessionStorage }[storageType];

  const saveSnapshot = useCallback(
    (newSearchText: string | undefined = undefined) => {
      if (apiRef?.current?.exportState && storage) {
        const currentState: GridInitialStatePro = apiRef.current.exportState();
        if (newSearchText !== undefined && currentState.filter?.filterModel) {
          currentState.filter.filterModel.quickFilterValues = [newSearchText];
        }
        storage.setItem(STORAGE_KEY, JSON.stringify(currentState));
        saveSnapshotWithCompatibility(currentState, storage);
      }
    },
    [apiRef, storage]
  );

  useEffect(() => {
    if (apiRef && setExternalActionsObj) {
      const externalFunctions = {
        showFilterPanel: (): void => showFilterPanel(apiRef, filterModel),
        showColumnManagerPanel: (): void =>
          showColumnManagerPanel(apiRef, filterModel),
        setDensityCompact: (): void => setTableDensity(apiRef, "compact"),
        setDensityStandard: (): void => setTableDensity(apiRef, "standard"),
        setDensityComfortable: (): void =>
          setTableDensity(apiRef, "comfortable"),
        areFiltersApplied: (): boolean => areFiltersApplied(apiRef)
      };
      setExternalActionsObj(externalFunctions);
    }
  }, [apiRef, filterModel, setExternalActionsObj]);

  useLayoutEffect(() => {
    const savedState = getStoragedState(storage);
    setInitialState(savedState || {});
    if (savedState?.filter?.filterModel) {
      setFilterModel(savedState.filter.filterModel);
    }
    if (savedState?.sorting?.sortModel) {
      setSortModel(savedState.sorting.sortModel);
    }
    if (savedState?.columns?.columnVisibilityModel) {
      setColumnVisibilityModel(savedState.columns.columnVisibilityModel);
    }
    // TODO: (Beyond parity) Save page isn't currently supoprted by prev table
    // if (savedState?.pagination) {
    //   setPaginationModel(savedState.pagination);
    // }
    return () => saveSnapshot();
  }, [saveSnapshot, storage]);

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        gridHeaders(EmployeeTableHeaders.FirstName),
        gridHeaders(EmployeeTableHeaders.LastName),
        gridHeaders(EmployeeTableHeaders.ExternalId),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Status, {
              session,
              language,
              onEditStatusClick,
              options: statuses
            })
        ],
        gridHeaders(EmployeeTableHeaders.Division, {
          options: divisions
        }),
        gridHeaders(EmployeeTableHeaders.Manager, {
          options: managers
        }),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Groups, {
              options: groups
            })
        ],
        ...[
          deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Reactivate, {
              onReactivateClick
            })
        ],
        ...[!deactivatedMode && gridHeaders(EmployeeTableHeaders.Arrow)]
      ].filter(Boolean) as GridColDef[],
    [
      session,
      language,
      onEditStatusClick,
      statuses,
      divisions,
      managers,
      groups,
      deactivatedMode,
      onReactivateClick
    ]
  );

  const onPaginationChange = useCallback(
    (model: GridPaginationModel): void => {
      const newPaginationModel: GridPaginationModel = {
        ...paginationModel,
        ...model
      };
      setPaginationModel(newPaginationModel);
      onPageChange?.(newPaginationModel.page, newPaginationModel.pageSize);

      saveSnapshot();
      requestAnimationFrame(() => {
        // requestAnimationFrame is used to ensure that
        // the scroll is done after the page change
        setTimeout(() => {
          // setTimeout is used to ensure that the scroll
          // is the last action done after the page change
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
      });
    },
    [onPageChange, paginationModel, saveSnapshot]
  );

  const getTogglableColumns = useCallback(
    (columns: GridColDef[]): string[] =>
      columns
        .filter(
          column =>
            ![
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              EmployeeTableHeaders.Arrow,
              EmployeeTableHeaders.Reactivate
            ].includes(column.field)
        )
        .map(column => column.field),
    []
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel): void => {
      const newSortModel = { sortModel: [...sortModel] };
      const sortingString = sortModelToString(newSortModel.sortModel);
      setSortModel(sortModel);
      onSortChange?.(sortingString);
      saveSnapshot();
    },
    [onSortChange, saveSnapshot]
  );

  const onFilterModelChange = (newFilterModel: GridFilterModel): void => {
    const filterAction = callFilterAction(
      filterModel,
      newFilterModel,
      dispatch,
      deactivatedMode
    );
    setFilterModel(filterAction);
    saveSnapshot();
  };

  const onColumnVisibilityModelChange = useCallback(
    (newColumnVisibilityModel: GridColumnVisibilityModel): void => {
      setColumnVisibilityModel(newColumnVisibilityModel);
      saveSnapshot();
    },
    [saveSnapshot]
  );

  const onRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel): void => {
      onSelectEmployees?.(newRowSelectionModel as string[]);
    },
    [onSelectEmployees]
  );

  const memorizedSlots = React.useMemo(
    () => ({
      cell: (props: GridCellProps): JSX.Element => (
        <MemorizedCellRow {...props} clickable={!deactivatedMode} />
      ),
      columnMenu: (props: GridColumnMenuProps): JSX.Element => (
        <MemorizedColumnMenu {...props} />
      ),
      noResultsOverlay: () => <MemorizedNoRows />,
      noRowsOverlay: () => <MemorizedNoRows />
    }),
    [deactivatedMode]
  );

  const slots = {
    filterPanel: () => (
      <CustomPanelFilter
        selectedFilters={filterModel?.items?.map(item => item.field) || []}
        showAddFilterButton={
          filterModel?.items?.length !== (deactivatedMode ? 2 : 4)
        }
        showRemoveAllButton={filterModel?.items?.length !== 0}
      />
    )
  };

  const setArrowColumnLast = useCallback(
    (apiRef: React.MutableRefObject<GridApiPro>): void => {
      const { left, right } = apiRef.current.getPinnedColumns();
      const arrowKey = EmployeeTableHeaders.Arrow;

      if (!right) {
        return;
      }

      if (right.slice(-1)[0] === arrowKey) {
        return;
      }

      apiRef.current.setPinnedColumns({
        left,
        right: [...right.filter(col => col !== arrowKey), arrowKey]
      });
    },
    []
  );

  return (
    <DataGrid
      // API
      apiRef={apiRef}
      // Data
      rows={rows}
      columns={columns}
      rowCount={rowCount}
      // Custom components
      slots={{
        ...memorizedSlots,
        ...slots
      }}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton"
        },
        columnsManagement: {
          getTogglableColumns
        }
      }}
      // Sorting
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      // Filtering
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      // Column visibility
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      // Pagination
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      // Clicking
      getRowClassName={() => (!deactivatedMode ? "cursor-pointer" : "")}
      disableRowSelectionOnClick
      // Selection
      rowSelection
      checkboxSelection={!deactivatedMode}
      // TODO: Should general select, select all database rows
      onRowSelectionModelChange={onRowSelectionModelChange}
      // Pinned columns
      onPinnedColumnsChange={() => setArrowColumnLast(apiRef)}
      // Status
      loading={isLoading || initialState === undefined}
      initialState={{
        ...initialState,
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: [EmployeeTableHeaders.Arrow]
        }
      }}
      disableColumnPinning
      density="compact"
    />
  );
};

const isSameData = (
  prevProps: EmployeesTableProps,
  nextProps: EmployeesTableProps
): boolean => {
  return (
    prevProps.displayList.employees?.map(emp => emp.id).join() ===
      nextProps.displayList.employees?.map(emp => emp.id).join() &&
    prevProps.deactivatedMode === nextProps.deactivatedMode &&
    prevProps.isLoading === nextProps.isLoading
  );
};

const MemorizedEmployeesTable = memo(EmployeesTable, isSameData);

export default MemorizedEmployeesTable;
