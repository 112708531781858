import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from "react";
import { Card, Container, Dropdown, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import clsx from "clsx";

import {
  EmployeesTableHeaderMuiGrid,
  NewTableBanner
} from "./EmployeesTableHeader";

import * as api from "~/api";
import { DeleteAttendanceStatusModal } from "~/components/DeleteAttendanceStatusModal/DeleteAttendanceStatusModal";
import {
  EditStatusModal,
  EditStatusModalProps
} from "~/components/EditStatusModal/EditStatusModal";
import { AddGroupsModal } from "~/components/GroupModals/AddGroupsModal";
import { RemoveFromGroupsModal } from "~/components/GroupModals/RemoveFromGroupsModal";
import { ScheduleAttendanceStatusModal } from "~/components/ScheduleAttendanceStatusModal/ScheduleAttendanceStatusModal";
import { MutableSessionContext } from "~/lib/context/";
import {
  EmployeeDisplayList,
  employeeDisplayReducer,
  EmployeeFilterActions,
  FilterListName,
  initialDisplayState
} from "~/lib/employeesList";
import {
  areAllSelected,
  filterIdsFromSelectedItems,
  FilterList,
  FilterListItem,
  getPersistedFilterData,
  persistFilterList,
  SessionKey,
  updateFilterList
} from "~/lib/filterList";
import { useLinguiLanguage } from "~/lib/hooks";
import { useApi } from "~/lib/hooks/useApi";
import {
  changeHealthStatus,
  deleteAttendanceStatusEvent,
  saveAttendanceStatusChange
} from "~/lib/status";
import {
  default as MuiEmployeesTable,
  STORAGE_KEY as MUI_GRID_STORAGE_KEY,
  ExternalActionsObj
} from "~/mui-components/EmployeesTable/EmployeesTable";
import Restricted from "~/ts-components/permissionProvider/Restricted";
import { GenericErrorText, TSButton, TSInput } from "~common";
import {
  EditEmployeeModal,
  ReactivateEmployeeModal,
  SelectableEmployeeList
} from "~employees";

import "./Employees.scss";

/** The top-level employees pane view */
export const Employees: FunctionComponent = () => {
  const { pathname, search } = useLocation();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const history = useHistory();
  const companyId = session.company?.id ?? "";
  const sessionStatusFilter = sessionStorage.getItem(
    SessionKey.employeesStatusFilter
  );
  const query = new URLSearchParams(search);

  const [searchText, setSearchText] = useState(
    sessionStorage.getItem(SessionKey.employeesSearchFilter) ?? ""
  );
  const [inactiveOnly, setInactiveOnly] = useState(false);
  const [initialResultsWithLoadFunc, setInitialResultsWithLoadFunc] =
    useState<InitialEmployeeResultsWithLoadFunction>();

  const [groups, setGroups] = useState<api.Group[]>();
  const [managers, setManagers] = useState<api.IdentificationEmployee[]>();
  const [
    initialGroupsAndManagersIDFiltersPopulated,
    setInitialGroupsAndManagersIDFiltersPopulated
  ] = useState(false);
  const [
    initialDivisionIDFiltersPopulated,
    setInitialDivisionIDFiltersPopulated
  ] = useState(false);
  const [showAddGroupsModal, setShowAddGroupsModal] = useState(false);
  const [showRemoveFromGroupsModal, setShowRemoveFromGroupsModal] =
    useState(false);
  const [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState(false);
  const [editStatusState, setEditStatusState] = useState<EditStatusState>();
  const [scheduleAttendanceStatusModal, setScheduleAttendanceStatusModal] =
    useState<AttendanceStatusModalState>();
  const [deleteAttendanceStatusModal, setDeleteAttendanceStatusModal] =
    useState<AttendanceStatusModalState>();
  const [reactivateModalEmployee, setReactivateModalEmployee] =
    useState<api.Employee>();
  const [isShowMuiDataGridToggle, setIsShowMuiDataGridToggle] = useState(false);
  const [isShowMuiDataGrid, setIsShowMuiDataGrid] = useState(
    localStorage.getItem("isShowMuiDataGrid") === "true"
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [ordering, setOrdering] = useState<string | null>(
    sessionStorage.getItem(SessionKey.employeesSorting)
  );
  const [muiGridApiRef, setMuiGridApiRef] = useState<
    ExternalActionsObj | undefined
  >();

  const filteredStatus = query.get("status") ?? undefined;
  let initialStatusFilter: Array<string> | undefined;

  if (filteredStatus) {
    const statusFilters = [filteredStatus];
    initialStatusFilter = statusFilters;
    // Clear filters since we are applying a url param filter
    Object.values(SessionKey).forEach(key => sessionStorage.removeItem(key));
    sessionStorage.setItem(
      SessionKey.employeesStatusFilter,
      JSON.stringify(statusFilters)
    );
    history.replace(pathname);
  } else if (sessionStatusFilter) {
    initialStatusFilter = JSON.parse(sessionStatusFilter);
  }

  const filterListFromEmployeeDisplayList = useCallback(
    (
      displayList: EmployeeDisplayList,
      newData: FilterListItem[],
      listName: FilterListName,
      sessionKey: SessionKey
    ): FilterList<FilterListItem> =>
      updateFilterList(displayList[listName], newData, sessionKey),
    []
  );

  const employeeAdditionalFields = useMemo(
    () => ({
      activeEvents: true,
      ...(session.features.full_covid_health_statuses
        ? { healthSurveyActivity: true }
        : {})
    }),
    [session.features.full_covid_health_statuses]
  );

  const [employeeDisplay, employeesDispatch] = useReducer(
    (state: EmployeeDisplayList, action: EmployeeFilterActions) => {
      setIsLoading(state.loading);
      const result = employeeDisplayReducer(state, action);
      setIsLoading(result.loading);
      return result;
    },
    initialDisplayState(
      session.features.full_covid_health_statuses,
      session.labels,
      language,
      initialStatusFilter
    )
  );

  const { selectedEmployeeIds } = employeeDisplay;

  const employeesSelected = Boolean(selectedEmployeeIds.length);

  // removableGroups will generate the set of groups that includes any
  // of the selected employees - this is only generated when
  // showRemoveFromGroupsModal is true to avoid doing unneeded work
  const removableGroups = useMemo(() => {
    if (!showRemoveFromGroupsModal || !groups) {
      return [];
    }
    const multipleSelectedEmployees = selectedEmployeeIds.length > 1;
    return groups.flatMap(group => {
      const employeeCount = employeeDisplay.employees.filter(
        employee =>
          selectedEmployeeIds.includes(employee.id) &&
          employee.groups?.filter(g => g.id === group.id).length
      ).length;
      if (!employeeCount) {
        return [];
      }
      return [
        {
          ...group,
          ...(multipleSelectedEmployees
            ? { name: `${group.name} (${employeeCount})` }
            : {})
        }
      ];
    });
  }, [
    employeeDisplay.employees,
    groups,
    selectedEmployeeIds,
    showRemoveFromGroupsModal
  ]);

  const filterTeamLeadIds = useMemo(
    () => filterIdsFromSelectedItems(employeeDisplay.activeManagers.items),
    [employeeDisplay.activeManagers.items]
  );

  const filterGroupIds = useMemo(
    () => filterIdsFromSelectedItems(employeeDisplay.activeGroups.items),
    [employeeDisplay.activeGroups.items]
  );

  const filterDivisionIds = useMemo(
    () => filterIdsFromSelectedItems(employeeDisplay.activeDivisions.items),
    [employeeDisplay.activeDivisions.items]
  );

  const filterStatusKeys = useMemo(() => {
    const result = filterIdsFromSelectedItems(
      employeeDisplay.activeStatuses.items
    );
    if (areAllSelected(employeeDisplay.activeStatuses, false)) {
      sessionStorage.removeItem(SessionKey.employeesStatusFilter);
    } else {
      sessionStorage.setItem(
        SessionKey.employeesStatusFilter,
        JSON.stringify(result)
      );
    }
    return result as api.StatusFilterChoices[];
  }, [employeeDisplay.activeStatuses]);

  const allStatusItems = Object.entries(
    employeeDisplay.activeStatuses.items
  ).map(([_, { item }]) => item);

  const allDivisionItems = Object.entries(
    employeeDisplay.activeDivisions.items
  ).map(([_, { item }]) => item);

  const areAllManagersSelected = areAllSelected(
    employeeDisplay.activeManagers,
    true
  );
  const filterBlankTeamLead = employeeDisplay.activeManagers.displayEmpty;
  const teamLeadIds = useMemo(
    () =>
      areAllManagersSelected
        ? undefined
        : [...filterTeamLeadIds, ...(filterBlankTeamLead ? ["None"] : [])],
    [areAllManagersSelected, filterBlankTeamLead, filterTeamLeadIds]
  );

  const areAllGroupsSelected = areAllSelected(
    employeeDisplay.activeGroups,
    true
  );
  const filterBlankGroup = employeeDisplay.activeGroups.displayEmpty;
  const groupIds = useMemo(
    () =>
      inactiveOnly || areAllGroupsSelected
        ? undefined
        : [...filterGroupIds, ...(filterBlankGroup ? ["None"] : [])],
    [areAllGroupsSelected, filterBlankGroup, filterGroupIds, inactiveOnly]
  );

  const areAllDivisionsSelected = areAllSelected(
    employeeDisplay.activeDivisions,
    true
  );
  const filterBlankDivision = employeeDisplay.activeDivisions.displayEmpty;
  const divisionIds = useMemo(
    () =>
      areAllDivisionsSelected
        ? undefined
        : [...filterDivisionIds, ...(filterBlankDivision ? ["None"] : [])],
    [areAllDivisionsSelected, filterBlankDivision, filterDivisionIds]
  );

  const statuses = useMemo(() => {
    if (inactiveOnly || areAllSelected(employeeDisplay.activeStatuses, false)) {
      return;
    }
    // Replace "unknown" with "None" for the purposes of calling our
    // retrieveEmployees() API. The back-end allows filtering for
    // "unknown" (with is a health_status only concept) or "None" which
    // means no health_status and no attendance_status
    const unknown = filterStatusKeys.find(key => key === "unknown");
    return [
      ...filterStatusKeys.filter(key => key !== "unknown"),
      ...(unknown ? ["None"] : [])
    ] as api.StatusFilterChoices[];
  }, [employeeDisplay.activeStatuses, filterStatusKeys, inactiveOnly]);

  const forceEmptyResults =
    (!areAllManagersSelected &&
      filterTeamLeadIds.length === 0 &&
      !filterBlankTeamLead) ||
    (!areAllDivisionsSelected &&
      filterDivisionIds.length === 0 &&
      !filterBlankDivision) ||
    (!areAllGroupsSelected &&
      filterGroupIds.length === 0 &&
      !filterBlankGroup) ||
    (!areAllManagersSelected &&
      filterDivisionIds.length === 0 &&
      !filterBlankDivision) ||
    filterStatusKeys.length === 0;

  // Data Loaders

  const loadEmployees =
    useCallback(async (): Promise<InitialEmployeeResults> => {
      try {
        if (
          !initialDivisionIDFiltersPopulated ||
          !initialGroupsAndManagersIDFiltersPopulated
        ) {
          // Not fully loaded, avoid calling network APIs...
          return { next: null, count: 0 };
        }

        if (forceEmptyResults && !isShowMuiDataGrid) {
          return { employees: [], next: null, count: 0 };
        }

        const response = await api.retrieveEmployees(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          session.company!.id,
          {
            ...employeeAdditionalFields,
            limit: pageSize,
            teamLeadIds,
            groupIds,
            divisionIds,
            statuses,
            inactiveOnly,
            groups: true,
            page: page + 1,
            ...(searchText ? { nameOrId: searchText } : {}),
            ...(ordering ? { ordering } : {})
          },
          isShowMuiDataGridToggle && isShowMuiDataGrid
        );

        if (response.ok) {
          return {
            employees: response.data.results,
            next: response.data.next,
            count: response.data.count || 0
          };
        } else {
          console.debug(
            "loadEmployees: could not retrieve employees: ",
            response.errors
          );
          return { employees: [], next: null, count: 0 };
        }
      } catch (error) {
        console.error("loadEmployees: error: ", error);
        return { employees: [], next: null, count: 0 };
      }
    }, [
      initialDivisionIDFiltersPopulated,
      initialGroupsAndManagersIDFiltersPopulated,
      forceEmptyResults,
      session.company,
      employeeAdditionalFields,
      teamLeadIds,
      groupIds,
      divisionIds,
      statuses,
      searchText,
      inactiveOnly,
      page,
      pageSize,
      isShowMuiDataGrid,
      isShowMuiDataGridToggle,
      ordering
    ]);

  const loadMoreEmployees = async (): Promise<void> => {
    if (!employeeDisplay.nextUrl) {
      return;
    }
    const response = await api.retrieveMoreEmployees(employeeDisplay.nextUrl, {
      groups: true
    });
    if (response.ok) {
      const { results, next, count } = response.data;
      employeesDispatch({
        type: "addEmployees",
        newEmployees: results,
        nextUrl: next,
        count: count || 0
      });
    } else {
      console.debug(
        "loadMoreEmployees: could not retrieve employees: ",
        response.errors
      );
    }
  };

  const requestAllEmployeeIds = useCallback(async (): Promise<void> => {
    if (!session.company) {
      console.error(`requestAllEmployeeIds: No company in session!`);
      return;
    }

    if (forceEmptyResults) {
      // There are no results, so no point in selecting all
      return;
    }

    employeesDispatch({
      type: "setLoadingEmployeeIds",
      newValue: true
    });

    let response: api.APIResponse<string[]> | undefined;
    try {
      response = await api.retrieveEmployeePublicIds(session.company.id, {
        teamLeadIds,
        groupIds,
        divisionIds,
        statuses,
        ...(searchText ? { nameOrId: searchText } : {}),
        ...(ordering ? { ordering } : {})
      });
    } catch {}

    employeesDispatch({
      type: "setLoadingEmployeeIds",
      newValue: false
    });

    if (response?.ok) {
      employeesDispatch({
        type: "setAllEmployeeIdsAndSelectAll",
        allEmployeeIds: response.data
      });
    } else {
      toast.error(<GenericErrorText />);
    }
  }, [
    divisionIds,
    forceEmptyResults,
    groupIds,
    ordering,
    searchText,
    session.company,
    statuses,
    teamLeadIds
  ]);

  const loadState = async (): Promise<void> => {
    employeesDispatch({
      type: "loadDivisions",
      newState: {
        activeDivisions: filterListFromEmployeeDisplayList(
          employeeDisplay,
          session?.divisions ?? [],
          "activeDivisions",
          SessionKey.employeesDivisionFilter
        ),
        count: employeeDisplay.count
      }
    });
    setInitialDivisionIDFiltersPopulated(true);

    if (
      !getPersistedFilterData(SessionKey.employeesGroupFilter) &&
      !getPersistedFilterData(SessionKey.employeesManagerFilter)
    ) {
      // Optimization: If we have no group or manager filters stored,
      // then we can signal that the filter ID lists are already stable,
      // thereby enabling us to fetch the first set of employees
      setInitialGroupsAndManagersIDFiltersPopulated(true);
    }

    const [newGroups, teamLeads] = await Promise.all([
      loadGroups(),
      loadManagers()
    ]);

    setGroups(newGroups.data);
    setManagers(teamLeads);

    employeesDispatch({
      type: "loadGroupsAndLeads",
      newState: {
        activeGroups: filterListFromEmployeeDisplayList(
          employeeDisplay,
          newGroups.data ?? [],
          "activeGroups",
          SessionKey.employeesGroupFilter
        ),
        activeManagers: filterListFromEmployeeDisplayList(
          employeeDisplay,
          teamLeads,
          "activeManagers",
          SessionKey.employeesManagerFilter
        ),
        count: employeeDisplay.count
      }
    });
    setInitialGroupsAndManagersIDFiltersPopulated(true);
  };

  const loadManagers = async (): Promise<api.IdentificationEmployee[]> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const response = await api.getCompanyEmployees(session.company!.id, {
      teamLeadsOnly: true,
      limit: api.MAX_LIMIT
    });

    if (response.ok) {
      return response.data.results;
    } else {
      console.debug(
        "loadManagers: could not retrieve managers: ",
        response.errors
      );
      return [];
    }
  };

  const { call: loadGroups } = useApi<api.Group[]>(
    api.groupsRetrieveUrl(companyId)
  );

  const addEmployeesToGroups = async (
    groups: api.GroupWriter[]
  ): Promise<void> => {
    if (!companyId) {
      console.error(`addEmployeesToGroups: No company in session!`);
      return;
    }

    const employeeText =
      selectedEmployeeIds.length > 1
        ? `${selectedEmployeeIds.length} employees`
        : `1 employee`;
    const groupText =
      groups.length > 1 ? `${groups.length} groups` : groups?.[0].name;

    let responses:
      | (
          | api.APIErrorResponse
          | api.APISuccessResponse<object>
          | api.APISuccessResponse<api.Group>
        )[]
      | undefined;

    try {
      responses = await Promise.all(
        groups.map(group =>
          typeof group.id === "string"
            ? api.addEmployeesToGroup(
                companyId,
                group as api.Group,
                selectedEmployeeIds
              )
            : api.createGroup(companyId, {
                company_id: companyId,
                name: group.name,
                employee_ids: selectedEmployeeIds
              })
        )
      );
    } catch {}

    const duplicateIndex =
      responses?.findIndex(resp => resp.status === 409) ?? -1;

    if (responses?.every(response => response.ok)) {
      loadState();
      toast.success(`${employeeText} added to ${groupText}.`);
    } else if (duplicateIndex === -1) {
      toast.error(`Error trying to add ${employeeText} to ${groupText}.`);
    } else {
      toast.error(
        `Your company already has a group named "${groups?.[duplicateIndex].name}",
         please enter a different group name.`
      );
    }
  };

  const removeEmployeesFromGroups = async (
    groups: api.Group[]
  ): Promise<void> => {
    const companyId = session.company?.id;
    if (!companyId) {
      console.error(`removeEmployeesFromGroups: No company in session!`);
      return;
    }

    const employeeText =
      selectedEmployeeIds.length > 1
        ? `${selectedEmployeeIds.length} employees`
        : `1 employee`;
    const groupText =
      groups.length > 1 ? `${groups.length} groups` : groups?.[0].name;

    let responses: api.APIResponse<object>[] | undefined;

    try {
      responses = await Promise.all(
        groups.map(group =>
          api.removeEmployeesFromGroup(
            companyId,
            group as api.Group,
            selectedEmployeeIds
          )
        )
      );
    } catch {}

    if (responses && responses.every(response => response.ok)) {
      loadState();
      toast.success(`${employeeText} removed from ${groupText}.`);
    } else {
      toast.error(`Error trying to remove ${employeeText} from ${groupText}.`);
    }
  };

  // Event handlers

  const handleSearchChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSearchText(event.target.value);
  };

  const onSelected = (
    employee: api.Employee,
    index: number,
    selected: boolean
  ): void => {
    employeesDispatch({
      type: "selectOne",
      index,
      newValue: selected
    });
  };

  const onSelectEmployees = (selectedEmployeeIds: string[]): void => {
    employeesDispatch({
      type: "selectSome",
      selectedEmployeeIds
    });
  };

  const onAddGroups = (groups: api.GroupWriter[]): void => {
    addEmployeesToGroups(groups);
    setShowAddGroupsModal(false);
  };

  const onClickAddToGroups = (): void => {
    if (employeesSelected) {
      setShowAddGroupsModal(true);
    }
  };

  const onRemoveFromGroups = (groups: api.Group[]): void => {
    removeEmployeesFromGroups(groups);
    setShowRemoveFromGroupsModal(false);
  };

  const onClickRemoveFromGroup = (): void => {
    if (employeesSelected) {
      setShowRemoveFromGroupsModal(true);
    }
  };

  const reloadEmployee = async (employee: api.Employee): Promise<boolean> => {
    const employeeResponse = await api.retrieveEmployee(
      employee.company_id,
      employee.id,
      employeeAdditionalFields
    );
    if (employeeResponse.ok) {
      employeesDispatch({
        type: "updateEmployee",
        updatedEmployee: employeeResponse.data
      });
    }
    return employeeResponse.ok;
  };

  const onEditStatusClick = (
    employee: api.Employee,
    labelKey: api.AnyStatus
  ): void => {
    if (api.isCovidHealthStatus(labelKey)) {
      setEditStatusState({
        employee,
        labelKey: labelKey as api.CovidHealthStatus
      });
    } else {
      const event = (employee.active_events ?? []).find(
        ({ label }) => label.name === labelKey
      );
      setScheduleAttendanceStatusModal({ employee, event });
    }
  };

  const onAttendanceStatusSave = async (
    startDate: api.ISODateTime,
    endDate: api.ISODateTime,
    status: api.AttendanceStatus,
    note?: string
  ): Promise<void> => {
    const { employee, event } = scheduleAttendanceStatusModal ?? {};
    if (!employee) {
      return;
    }
    if (
      await saveAttendanceStatusChange(
        employee,
        startDate,
        endDate,
        status,
        session.labels,
        language,
        note,
        event
      )
    ) {
      reloadEmployee(employee);
    }
  };

  const onClickAttendanceStatusDelete = (): void => {
    const { employee, event } = scheduleAttendanceStatusModal ?? {};

    setScheduleAttendanceStatusModal(undefined);
    setDeleteAttendanceStatusModal({ employee, event });
  };

  const onAttendanceStatusDelete = async (): Promise<void> => {
    const { employee, event } = deleteAttendanceStatusModal ?? {};
    if (!event || !employee) {
      return;
    }
    if (await deleteAttendanceStatusEvent(employee, event)) {
      reloadEmployee(employee);
    }
  };

  const onEditStatus = async (
    employee: api.Employee,
    healthStatus: api.CovidHealthStatus,
    noteBody: string
  ): Promise<void> => {
    if (
      await changeHealthStatus(
        employee,
        healthStatus,
        session.labels,
        language,
        noteBody
      )
    ) {
      reloadEmployee(employee);
    }
  };

  const onReactivateEmployee = async (
    employee: api.Employee
  ): Promise<void> => {
    let response: api.APIResponse<api.Employee> | undefined;
    try {
      response = await api.activateEmployee(employee.company_id, employee.id);
    } catch {}
    if (response?.ok) {
      // Because activating employees can change group membership lists, we
      // call loadState() here:
      loadState();

      toast.success(`${employee.name} has been reactivated.`);
      employeesDispatch({
        type: "removeEmployee",
        removedEmployee: employee
      });
    } else {
      toast.error(<GenericErrorText />);
    }
  };

  const createModalCancelClickHandler = (): void => {
    setShowCreateEmployeeModal(false);
  };

  // Effects

  useEffect(() => {
    sessionStorage.setItem(SessionKey.employeesSearchFilter, searchText);
  }, [searchText]);

  useEffect(() => {
    if (initialDivisionIDFiltersPopulated) {
      persistFilterList(
        employeeDisplay.activeDivisions,
        filterDivisionIds,
        true,
        SessionKey.employeesDivisionFilter
      );
    }

    if (initialGroupsAndManagersIDFiltersPopulated) {
      persistFilterList(
        employeeDisplay.activeGroups,
        filterGroupIds,
        true,
        SessionKey.employeesGroupFilter
      );
      persistFilterList(
        employeeDisplay.activeManagers,
        filterTeamLeadIds,
        true,
        SessionKey.employeesManagerFilter
      );
    }
  }, [
    employeeDisplay,
    filterDivisionIds,
    filterGroupIds,
    filterTeamLeadIds,
    initialDivisionIDFiltersPopulated,
    initialGroupsAndManagersIDFiltersPopulated
  ]);

  useEffect(() => {
    async function refetchEmployees(): Promise<void> {
      employeesDispatch({
        type: "requesting"
      });
      const result = await loadEmployees();
      setInitialResultsWithLoadFunc({
        ...result,
        loadFunction: loadEmployees
      });
    }
    refetchEmployees();
  }, [loadEmployees]);

  useEffect(() => {
    if (!initialResultsWithLoadFunc) {
      return;
    }
    const { employees, next, count, loadFunction } = initialResultsWithLoadFunc;
    if (loadEmployees !== loadFunction) {
      // If the loadFunction has changed since the time these results
      // were loaded, we will ignore these results

      // This solves problems related to "out of order" API responses
      // that are common when sending several queries quickly as a result
      // of typing in the Search filter (for names)
      return;
    }
    if (employees) {
      employeesDispatch({
        type: "setEmployees",
        newEmployees: employees,
        nextUrl: next,
        count
      });
    }
  }, [initialResultsWithLoadFunc, loadEmployees]);

  useEffect(() => {
    setIsShowMuiDataGridToggle(
      session?.features?.enabled_flags?.includes("mui_data_grid")
    );
    loadState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowMuiDataGridChange = (checked: boolean): void => {
    employeesDispatch({ type: "clearAllFilters" });
    sessionStorage.removeItem(MUI_GRID_STORAGE_KEY);
    setIsShowMuiDataGrid(checked);
    localStorage.setItem("isShowMuiDataGrid", checked.toString());
  };

  return (
    <div className={clsx("ts", isShowMuiDataGrid && "h-full max-h-full")}>
      <Container
        fluid
        className={clsx(
          "standard-page",
          isShowMuiDataGrid && "h-full max-h-full flex flex-col"
        )}
      >
        {isShowMuiDataGridToggle && (
          <NewTableBanner
            isToogleOn={isShowMuiDataGrid}
            setToogle={onShowMuiDataGridChange}
          />
        )}

        {isShowMuiDataGridToggle && isShowMuiDataGrid ? (
          <EmployeesTableHeaderMuiGrid
            muiGridApiRef={muiGridApiRef}
            searchText={searchText}
            inactiveOnly={inactiveOnly}
            editGroupsDisabled={!employeesSelected}
            editGroupsHidden={inactiveOnly}
            handleSearchChange={handleSearchChange}
            setShowCreateEmployeeModal={setShowCreateEmployeeModal}
            setInactiveOnly={setInactiveOnly}
            onClickAddToGroups={onClickAddToGroups}
            onClickRemoveFromGroup={onClickRemoveFromGroup}
          />
        ) : (
          <div className="employees-header">
            <div className="employees-header-left">
              <h1>Employees</h1>
            </div>

            <div className="employees-header-right">
              <Form.Check
                className="check-lg show-deactivated-switch"
                style={{ paddingLeft: "1rem" }}
                label="Show Deactivated Employees"
                type="switch"
                checked={inactiveOnly}
                onChange={({ target: { checked } }) => setInactiveOnly(checked)}
              />

              <TSInput
                id="search-employees"
                defaultValue={searchText}
                type="search"
                placeholder="Search"
                onChange={handleSearchChange}
              />
              <div className="header-buttons">
                <Restricted
                  to={[
                    "Groups.READ",
                    "Groups.CREATE",
                    "Groups.UPDATE",
                    "Groups.DELETE"
                  ]}
                >
                  <Dropdown align="end">
                    <Dropdown.Toggle
                      id="edit-groups-button"
                      variant="primary"
                      disabled={inactiveOnly || !employeesSelected}
                    >
                      <>
                        Edit Groups
                        <i className="btn-icon icon-caret-bottom btn-icon-end"></i>
                      </>
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                      <Dropdown.Item onClick={onClickAddToGroups}>
                        Add Selected to Groups
                      </Dropdown.Item>
                      <Dropdown.Item onClick={onClickRemoveFromGroup}>
                        Remove Selected from Groups
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Restricted>
                <Restricted to="EmployeeCreate.CREATE">
                  <TSButton
                    variant="primary"
                    onClick={() => setShowCreateEmployeeModal(true)}
                    disabled={inactiveOnly}
                  >
                    Add Employee
                  </TSButton>
                </Restricted>
              </div>
            </div>
          </div>
        )}

        {isShowMuiDataGridToggle && isShowMuiDataGrid ? (
          <MuiEmployeesTable
            deactivatedMode={inactiveOnly}
            displayList={employeeDisplay}
            dispatch={employeesDispatch}
            searchText={searchText}
            groups={groups ?? []}
            managers={managers ?? []}
            statuses={allStatusItems}
            divisions={allDivisionItems}
            // onRequestAllEmployeeIds={requestAllEmployeeIds}
            onEditStatusClick={onEditStatusClick}
            onReactivateClick={setReactivateModalEmployee}
            onPageChange={(page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            initPageSize={100}
            onSelectEmployees={onSelectEmployees}
            onSortChange={setOrdering}
            isLoading={isLoading}
            setExternalActionsObj={setMuiGridApiRef}
          />
        ) : (
          <Card>
            <SelectableEmployeeList
              deactivatedMode={inactiveOnly}
              displayList={employeeDisplay}
              dispatch={employeesDispatch}
              groups={groups ?? []}
              managers={managers ?? []}
              statuses={allStatusItems}
              onChange={onSelected}
              onLoadMoreEmployees={loadMoreEmployees}
              onRequestAllEmployeeIds={requestAllEmployeeIds}
              onEditStatusClick={onEditStatusClick}
              onReactivateClick={setReactivateModalEmployee}
            />
          </Card>
        )}

        {showAddGroupsModal && (
          <AddGroupsModal
            employeeIds={selectedEmployeeIds}
            groups={groups ?? []}
            onClose={() => setShowAddGroupsModal(false)}
            onConfirm={onAddGroups}
          />
        )}

        {showRemoveFromGroupsModal && (
          <RemoveFromGroupsModal
            employeeIds={selectedEmployeeIds}
            groups={removableGroups}
            onClose={() => setShowRemoveFromGroupsModal(false)}
            onConfirm={onRemoveFromGroups}
          />
        )}

        {editStatusState?.employee && (
          <EditStatusModal
            {...editStatusState}
            fullCovidHealthStatuses={
              session.features.full_covid_health_statuses
            }
            labelInfoMap={session.labels}
            onClose={() => setEditStatusState(undefined)}
            onConfirm={onEditStatus}
          />
        )}

        {scheduleAttendanceStatusModal?.employee && (
          <ScheduleAttendanceStatusModal
            event={scheduleAttendanceStatusModal.event}
            timezone={scheduleAttendanceStatusModal.employee.timezone}
            onClose={() => setScheduleAttendanceStatusModal(undefined)}
            onSave={onAttendanceStatusSave}
            onClickDelete={onClickAttendanceStatusDelete}
          />
        )}

        {deleteAttendanceStatusModal?.employee &&
          deleteAttendanceStatusModal.event && (
            <DeleteAttendanceStatusModal
              employee={deleteAttendanceStatusModal.employee}
              event={deleteAttendanceStatusModal.event}
              onDelete={onAttendanceStatusDelete}
              onClose={() => setDeleteAttendanceStatusModal(undefined)}
            />
          )}
      </Container>

      <EditEmployeeModal
        show={showCreateEmployeeModal}
        modalCancelClickHandler={createModalCancelClickHandler}
        groups={groups}
        disableApi={true}
      />

      {reactivateModalEmployee && (
        <ReactivateEmployeeModal
          show
          employee={reactivateModalEmployee}
          onHide={() => setReactivateModalEmployee(undefined)}
          onConfirm={() => {
            onReactivateEmployee(reactivateModalEmployee);
            setReactivateModalEmployee(undefined);
          }}
        />
      )}
    </div>
  );
};

type EditStatusState = Omit<EditStatusModalProps, "onClose" | "labelInfoMap">;

interface AttendanceStatusModalState {
  employee?: api.Employee;
  event?: api.EmployeeEvent;
}

interface InitialEmployeeResults {
  employees?: api.Employee[];
  next: string | null;
  count: number;
}

interface InitialEmployeeResultsWithLoadFunction
  extends InitialEmployeeResults {
  loadFunction: Function;
}
