import * as React from "react";

import { default as MuiMenu, MenuProps } from "@mui/material/Menu";

import { adaptProps } from "../mui-stories-utils";
import ThemeLayout from "../Theme/ThemeLayout";

const Menu = (componentProps: MenuProps): React.ReactElement<MenuProps> => {
  const { children, props } = adaptProps(componentProps);

  return (
    <ThemeLayout>
      <MuiMenu {...props}>{children}</MuiMenu>
    </ThemeLayout>
  );
};

export default Menu;
